
import { Typography,  Box  } from '@mui/material'
import colors from '@studygenius/shared/Colors'

export const YellowFrame = ({children, sx1, sx2}) => {
    return(<Box sx={{backgroundColor: colors.main, width: '100%', height: '100%', ...sx1}}>
    <Box sx={{backgroundColor: colors.white, borderRadius: '50px', display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center', margin: 2, flexDirection: 'column', ...sx2}}>
        {children}
    </Box>
    </Box>
    )}

    export const TAB_VALUES = {
        AUTO_GENERATE: 'AUTO_GENERATE',
        SUMMARY: 'SUMMARY'
        // LINK_SUMMARY: 'LINK_SUMMARY',
        // MANUAL_INPUT: 'MANUAL_INPUT',
        // VIDEO_SUMMARY: 'VIDEO_SUMMARY'
      }



export const activeStates = {
    ALL : 'All',
    LEFT: 'Left',
    RIGHT: 'Right'
  }