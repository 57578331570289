import Backdrop from '@mui/material/Backdrop';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import colors from '@studygenius/shared/Colors';

const MyStyledBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: 'rgba(0,0,0,0.8)'
}));



export default function MyBackdrop({ open }) {
  return (
      <MyStyledBackdrop open={open} />
  );
}