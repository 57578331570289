'use client'
import React from 'react';
import { Box, Link, Typography, IconButton, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import colors from '@studygenius/shared/Colors';
import { MUIStyles } from '@studygenius/shared/Variables';
import {PrimaryLink} from './Components';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'
import {TAB_VALUES} from './addfact/Components';

import { WEB_LINKS } from '@studygenius/shared/Variables';


const getPair = (text, link) => ({text, link});

export default function Footer() {
    
    //   const classes = useStyles();
    const a = [0,0,0,0];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const links = [{header: 'General',
        items: [getPair('Home','/'),
        getPair('About us', '/about-us')
            // getPair('Pricing & subscriptions','YourFacts'),
            // getPair('My Library',WEB_LINKS.CATEGORY_LIST)
        ]},
    // {header: 'Add Facts',
    //     items: [
    //         getPair('Auto Generate', `/addfact?tab=${TAB_VALUES.AUTO_GENERATE}`),
    //         getPair('Summary From Link', `/addfact?tab=${TAB_VALUES.LINK_SUMMARY}`),
    //         getPair('Manual Input', `/addfact?tab=${TAB_VALUES.MANUAL_INPUT}`),
    //     ]
    // },
    {header: 'Contact us',
        items: [getPair('info@studygeniuspro.com', null)
        ]
    }];


  


    return (
        <footer  style={{backgroundColor: colors.footer}}>
            <Box sx={{paddingTop: {xs: '40', md: '90px'}, paddingBottom: '60px', paddingLeft: 2, paddingRight: 2, display: 'flex', flexDirection: {xs: 'column', md: 'row'}}}>
         
                <Box sx={{marginTop: 2, marginLeft: {xs: 0, md: '100px'}, width: '310px', marginRight: {xs: 2, md: '80px'}, marginBottom: {xs: 3, md: 0} }}>
                    <img src={'/inline-combo-2.png'} style={{height: '48px', width: '254px'}} />
                    <Typography sx={{...MUIStyles.mediumParagraph, marginTop: 2}}>Discover a joyful learning path to success, where every step is both fun and fulfilling.</Typography>
                </Box>
                {links.map((item) => <Box sx={{width: '190px', marginLeft: {xs: 0, md: 2}}}>
                    <Box sx={{marginTop: 2, marginRight: 2}}>
                        <Typography 
                            sx={{...MUIStyles.nuitoBold, color: colors.text, marginBottom: {xs: '10px', md: '40px'}
                            }}>{item.header}</Typography>
                        {item.items.map((link) => <PrimaryLink href={link.link} sx={{marginBottom: 2}}>{link.text}</PrimaryLink>)}
                    </Box>
                </Box>)}
       
      

 
            </Box>
            <Box sx={{ marginLeft: {xs: '16px', md: '100px'}, marginRight: {xs: '16px', md: '100px'}}}>
                <Box sx={{width: '100%', height: '1px', backgroundColor: colors.text}} />
                <Box sx={{display: 'flex', justifyContent: 'space-between', flexDirection: {xs: 'column', md: 'row', paddingBottom: '20px'}}}>
                    <Box item xs={12} md={4} sx={{paddingTop: '40px'}}>
                        <Typography sx={{...MUIStyles.mediumParagraph, color: colors.text}}>Copyright StudyGenius 2024</Typography>
                    </Box>

                    <Box sx={{paddingTop: '40px'}}>
                        <Box sx={{display: 'flex', flex: 1, alignItems: {xs: 'flex-start', md: 'center'}, justifyContent: isMobile ? 'flex-start' : 'flex-end', flexDirection: {xs: 'column', md: 'row'}}}>
                            <Typography sx={{...MUIStyles.mediumParagraph, color: colors.text}}>All rights reserved&nbsp;</Typography>

                            <Typography sx={{...MUIStyles.mediumParagraph, color: colors.text,fontSize: '24px', display: isMobile ?  'none' : 'inline-block'}}>|&nbsp;</Typography>

                            <PrimaryLink sx={{ color: '#4A3AFF', marginLeft: 0, marginTop: {xs: 2, md: 0}}} href="https://www.iubenda.com/terms-and-conditions/74869816">
  Terms and Conditions&nbsp;
                            </PrimaryLink>
                            <Typography sx={{color: colors.text, marginLeft: 0, fontSize: '24px', display: isMobile ?  'none' : 'inline-block'}}>|&nbsp;</Typography>

                            <PrimaryLink target="_blank"
rel="noopener noreferrer" sx={{ color: '#4A3AFF', marginLeft: 0, marginTop: {xs: 2, md: 0}}}  href="https://www.iubenda.com/privacy-policy/74869816">
  Privacy Policy
                            </PrimaryLink>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </footer>
    );
}