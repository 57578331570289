'use client';

import { useEffect } from 'react';

export default function ClientMenuSwap() {
  useEffect(() => {
    const simpleMenu = document.getElementById('simple-menu');
    const fullMenu = document.getElementById('full-menu');

    if (simpleMenu && fullMenu) {
      simpleMenu.style.display = 'none';
      fullMenu.style.display = 'block';
    }
  }, []);

  return null; // This component doesn't render anything visible
}